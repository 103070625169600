'use strict';

/* globals $ window jQuery document */

var accessibility = require('./accessibility'),
    breakpoint = require('./breakpoint'),
    carousel = require('./carousel'),
    validator = require('./validator'),
    newsletter = require('./newsletter'),
    forms = require('./forms'),
    progress = require('./progress'),
    cookieprivacy = require('./cookieprivacy'),
    dialog = require('./dialog'),
    cache = {
        $body: $('body'),
        $window: $(window),
        $document: $(document),
        $dialog: null
    },
    pdBeachList = require('./pagedesigner/beachlist');

// EADA-40 - The modal container should be labeled as such
var jqDialogOpenAdjust = function ($dialog) {
    $dialog = $dialog || $();

    var $dialogTitle = $dialog.find('.ui-dialog-title') || $(),
        $customDialogTitle = $dialog.find('.js-dialog-title') || $(),
        customDialogTitleID = $customDialogTitle.attr('id') || $customDialogTitle.uniqueId().attr('id');

    if ($dialog.length) {
        $dialog.attr('aria-modal', 'true')
        // EADA21-77 - ADA #8 - Popup US 15% - The close button is not labeled for screen reader users
        .find('.ui-dialog-titlebar-close').attr('aria-label', window.Resources.CLOSE)
        // EADA-17 - ADA for close button on jquery dialog
        .find('.ui-button-icon').attr('aria-hidden', 'true');
    }

    // EADA23-25 - Labelledby must link on the heading: dialog custom title ID
    if ($customDialogTitle.length) {
        $dialog.attr('aria-labelledby', customDialogTitleID);
    }

    // EADA23-24 - Put aria-hidden="true" on .ui-dialog-title
    if ($dialogTitle.length) {
        $dialogTitle.attr('aria-hidden', 'true');
    }
};

/**
* Handles the click action on the dialog
* @param {Object} event - the click event
* @returns {void}
*/
function onDialogClickAction(event) {
    var $target = $(event.target);
    if (!$target.hasClass('js-reservation')) { // click on inner svg case
        $target = $target.closest('.js-reservation');
    }
    var url = $target.attr('data-iframe-src');

    if (url !== null) {
        event.preventDefault();
        var $modalContent = $('.js-reservation-dialog'),
            html = $modalContent && $modalContent.length && $modalContent.html() || '', // html with close svg
            iframe = '<iframe src="{{IFRAME_URL}}" width="audo" height="auto" frameborder="0" allowTransparency="true" scrolling="yes"></iframe>';

        dialog.open({
            html: html + iframe.replace('{{IFRAME_URL}}', url),
            customCloseSelector: '.js-reservation-close',
            options: {
                dialogClass: 'reservation-modal',
                width: 'auto',
                closeOnEscape: true
            }
        });
    }
}

/**
 * Initializes the navigation menu
 * @returns {void}
 */
function navigationInit() {
    var $menu = $('.js-menu'),
        $menuOpen = $('.js-menuOpen'),
        $menuClose = $('.js-menuClose');

    $menuOpen.on('click', function () {
        window.vbqUtils.cache.$body.addClass('menu-active');
        $menu.addClass('show');

        // focus on close menu button on menu open
        $menuClose.focus();
    });

    $menuClose.on('click tap', function () {
        window.vbqUtils.cache.$body.removeClass('menu-active');
        $menu.removeClass('show');

        // focus on open menu button on menu close
        $menuOpen.focus();
    });

    // turn on circle focus in the defined wrapper
    accessibility.trapFocusInit($menu);

    // open menu if om= in window.location.href
    var url = window.location.href,
        params = url.split('?')[1];

    if (params && params.indexOf('om=1') > -1) {
        $menuOpen.trigger('click');
    }
}

/**
 * @private
 * @function
 * @description Initializes jQuery UI plugins
 */
function initializeJQueryUiPlugins() {
    var $customStyleInputs = $('.gl_radio, .gl_checkbox');

    $customStyleInputs
        .filter('input[type=radio], input[type=checkbox]')
        .add($customStyleInputs.find('input[type=radio], input[type=checkbox]'))
        .checkboxradio();
}

/**
 * @function
 * @description show progress loader
 * @param {Boolean} state - false to hide loader, undefined or true to show it
 * @param {Boolean} alert - false to not show alert, undefined or true to show it
 */
function loader(state, alert, $customLoader) {
    var $loader = $customLoader !== undefined ? $customLoader : $('.js-loader'),
        $alert = $loader.next('.js-progress-alert');

    if (state === undefined || state) {
        if (alert === undefined || alert) {
            $alert.html('<span>' + window.Resources.ALERT_MESSAGE + '</span>');
        }
        $loader.show();
    }
    else {
        if (!alert) {
            $alert.html('');
        }
        $loader.hide();
    }
}

/**
 * Initializes the DOM elements
 */
function initializeDom() {
    // Initialize the jQuery UI elements
    initializeJQueryUiPlugins();

    // Init any found carousel on the page
    carousel.init();
}

/**
 * Initializes the navigation events
 */
function initializeEvents() {
    navigationInit();
    validator.init();
    newsletter.init();
    forms.init();

    cache.$dialog = cache.$document.find('.js-reservation');
    cache.$dialog.on('click', onDialogClickAction);
}

var pages = {
    content: require('./pages/content/customerservice')
};

var app = {
    init: function () {
        window.vbqUtils = window.vbqUtils || {};
        window.vbqUtils = $.extend({}, window.vbqUtils, {
            breakpoint: breakpoint,
            cache: cache,
            loader: loader,
            initializeJQueryUiPlugins: initializeJQueryUiPlugins,
            // EADA-40 - The modal container should be labeled as such
            jqDialogOpenAdjust: jqDialogOpenAdjust,
            progress: progress
        });

        initializeDom();
        initializeEvents();
        cookieprivacy();

        // execute page specific initializations
        var ns = window.pageContext.ns;
        if (ns && pages[ns] && pages[ns].init) {
            pages[ns].init();
        }

        pdBeachList.init();
    }
};

/**
 * Initializes the application
 */
jQuery(document).ready(function ($) { // pass $ as parameter to scope it as GTM overrides it (rakuten)
    app.init();
});
