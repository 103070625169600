'use strict';

/* globals $ window document */

var cache = {},
    submitInProcess = false;

function initCache() {
    cache.$document = $(document);
    cache.$newsletter = cache.$document.find('.js-newsletter');
    cache.$newsletterLoader = cache.$newsletter.find('.js-newsletter-loader');
    cache.$newsletterForm = cache.$newsletter.find('.js-newsletter_subscription');
    cache.$email = cache.$newsletterForm.find('input[type=email]');
    cache.$optinconsent = cache.$newsletterForm.find('input[type=checkbox]');
    cache.$submit = cache.$newsletterForm.find('button[type=submit]');

    cache.erros = {
        format: window.Resources.NEWSLETTER_SUBSCRIBE_ERROR,
        global: window.Resources.TECHNICAL_ERROR
    };
}

function onSubmitAction(e) {
    e.preventDefault();

    if (submitInProcess) {
        return;
    }
    // block a new submit action
    submitInProcess = true;

    var email = cache.$email.val();
    var optinconsent = cache.$optinconsent.is(':checked');
    var validator = cache.$newsletterForm.validate();

    // do nothing if consent is not checked
    // form vill be validated automatically
    if (!optinconsent) {
        // allow a new submit action
        submitInProcess = false;
        return;
    }

    // disabled newsletter form
    cache.$newsletterForm.addClass('gl_form-disabled');
    window.vbqUtils.loader(true, false, cache.$newsletterLoader);

    $.ajax({
        url: window.Urls.subscribeToNewsletter,
        method: 'POST',
        data: {
            email: email,
            optinconsent: optinconsent
        },
        success: function (data) {
            // allow a new submit action
            submitInProcess = false;

            // enable newsletter form
            cache.$newsletterForm.removeClass('gl_form-disabled');
            window.vbqUtils.loader(false, false, cache.$newsletterLoader);

            // clear email field if no error
            if (!data.error) {
                // replace form with the success message
                cache.$newsletter.replaceWith($('<div>').html(window.Resources.FORM_SUBMIT_SUCCESS).addClass('form-success-message'));
                return;
            }

            // there is an error
            validator.showErrors({
                email: cache.errors[data.type]
            });
        }
    });
}

function initEvents() {
    cache.$newsletterForm.on('submit', onSubmitAction);
}

module.exports = {
    init: function () {
        initCache();
        initEvents();
    }
};
