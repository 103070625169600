'use strict';

var util = require('../util');
var beachlistCache = {
    $beachLists: $('.js-beachList'),
    $beachImages: $('.js-beachImage')
};

var beachlist = {
    /**
     * @private
     * @function
     * @description init events for the beachlist PD component
     */
    init: function () {
        util.forceLazyloadImagesByViewport(beachlistCache.$beachImages);

        beachlistCache.$beachLists.each(function () {
            var $beachList = $(this),
                $beachLinks = $beachList.find('.js-beachLink'),
                $beachInfos = $beachList.find('.js-beachInfo');

            $beachLinks.on('mouseenter focus', function() {
                var $link = $(this),
                    index = $link.data('num'),
                    $info = $beachInfos.filter('[data-num="' + index + '"]');

                $beachLinks.not($link).removeClass('active');
                $beachInfos.not($info).removeClass('active');

                $link.addClass('active');
                $info.addClass('active');
            });
        });
    }
};

module.exports = beachlist;
