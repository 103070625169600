'use strict';

/* global $ window document */

var cache = {},
    submitInProcess = false;

function initCache() {
    cache.$document = $(document);
    cache.$forms = cache.$document.find('.js-quote-form, .js-contact-form');
    cache.$errorWrapper = cache.$forms.find('.js-error_message');

    cache.errors = {
        validation: window.Resources.VALIDATION_ERROR,
        global: window.Resources.TECHNICAL_ERROR
    };
}

function onFormSubmitAction(event) {
    event.preventDefault();

    if (submitInProcess) {
        return;
    }

    submitInProcess = true;

    var $form = $(event.target),
        $loader = $form.siblings('.js-form-loader'),
        action = $form.attr('action'),
        method = $form.attr('method'),
        data = $form.serialize(),
        validator = $form.validate();

    // clear general error message
    cache.$errorWrapper.html('');

    // if form is not valid then do not make the ajax call
    if (!$form.valid()) {
        submitInProcess = false;
        return;
    }

    // disabled form
    $loader.addClass('gl_form-disabled');
    window.vbqUtils.loader(true, false, $loader);

    $.ajax({
        url: action,
        method: method,
        data: data,
        success: function (data) {
            // allow a new submit action
            submitInProcess = false;

            // enable newsletter form
            $loader.removeClass('gl_form-disabled');
            window.vbqUtils.loader(false, false, $loader);

            // clear form field values
            if (!data.error) {
                // replace form with the success message
                $form.replaceWith($('<div>').html(window.Resources.FORM_SUBMIT_SUCCESS).addClass('form-success-message'));
                return;
            }

            // show the error message
            if (data.fieldsErrors && Object.keys(data.fieldsErrors).length) {
                validator.showErrors(data.fieldsErrors);
                return;
            }

            // show a general error message
            cache.$errorWrapper.html(cache.errors[data.type]);
        }
    });
}

function initEvents() {
    cache.$forms.on('submit', onFormSubmitAction);
}

module.exports = {
    init: function () {
        initCache();
        initEvents();
    }
};
