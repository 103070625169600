'use strict';

/* globals $ Resources*/

/**
 * Set defaults for validator
 */
var defaults = $.validator.defaults;

defaults.errorClass = 'gl_error-message';
defaults.errorElement = 'span';
defaults.ignore = ':hidden:not(.forcevalidation)';
defaults.errorPlacement = function (error, element) {
    var $fieldset = $(element).closest('.gl_form-group');
    $fieldset.find('span.gl_error-message').remove();
    $fieldset.append(error);
};
defaults.highlight = function (element) {
    var $element = $(element),
        $formGroup = $element.closest('.gl_form-group'),
        $successContainer = $formGroup.find('.gl_input-success-container');

    if (!$element.attr('formnovalidate')) {
        if ($element.val() != $element.attr('placeholder')) {
            $formGroup.find('.backend-error-message').remove();
            $formGroup.addClass('gl_has-error');
        }

        $formGroup.removeClass('gl_has-success');
        $successContainer.attr('aria-hidden', 'true');
    }
};
defaults.unhighlight = function (element) {
    var $element = $(element),
        $formGroup = $element.closest('.gl_form-group'),
        $successContainer = $formGroup.find('.gl_input-success-container');

    if (!$element.attr('formnovalidate')) {
        $formGroup.removeClass('gl_has-error');
        $formGroup.find('.backend-error-message').remove();
        $formGroup.find('span.gl_error-message').remove();

        if (!$formGroup.hasClass('gl_no-success-allowed')) {
            if ($element.val() != $element.attr('placeholder')) {
                $formGroup.addClass('gl_has-success');
                $successContainer.attr('aria-hidden', 'false');
            }
        }
    }
};

/**
 * Add date validation
 */
$.validator.addMethod('date', function (value, el) {
    var pattern = el.dataset.regexp;
    var regExp = new RegExp(pattern);
    var success = regExp.test(value);

    return success;
});

/**
 * Add XSS check validation
 * Text fields must have 'xss' css class to be validated
 */
$.validator.addMethod('xss', function (value) {
    var regexp = new RegExp(Resources.FORM_XSS_PATTERN, 'gi');
    return !regexp.test(value);
}, Resources.FORM_XSS_ERROR_MESSAGE);

/**
* Extends the jQuery Validation Plugin with custom messages
*/
$.extend($.validator.messages, {
    required: Resources.VALIDATE_REQUIRED,
    email: Resources.VALIDATE_EMAIL,
    date: Resources.VALIDATE_DATE
});

var validator = {
    init: function () {
        $.validator.setDefaults(defaults); // security

        $('form:not(.suppress)').each(function () {
            $(this).validate(defaults);
        });
    }
};

module.exports = validator;
