'use strict';

var _isString = require('./lib/lodash.isstring');

var util = {
    /**
     * @function
     * @description show progress loader for filter refinements and products grid; on pdp form
     * @param {Boolean} state - false to hide loader, undefined or true to show it
     */
    progressLoader: function (state, $customLoader) {
        var $loader = $customLoader !== undefined ? $customLoader : $('.progress-loader'),
            $alert = $loader.next('.js-progress-alert');

        if (state === undefined || state) {
            $alert.html('<span>' + window.Resources.ALERT_MESSAGE + '</span>');
            $loader.show();
        }
        else {
            $alert.html('');
            $loader.hide();
        }
    },

    /**
     * @function
     * @description appends the parameter with the given name and value to the given url and returns the changed url
     * @param {String} url the url to which the parameter will be added
     * @param {String} name the name of the parameter
     * @param {String} value the value of the parameter
     */
    appendParamToURL: function (url, name, value) {
        // quit if the param already exists
        if (url.indexOf(name + '=') !== -1) {
            return url;
        }
        var separator = url.indexOf('?') !== -1 ? '&' : '?';
        return url + separator + name + '=' + encodeURIComponent(value);
    },

    /**
     * @function
     * @description remove the parameter and its value from the given url and returns the changed url
     * @param {String} url the url from which the parameter will be removed
     * @param {String} name the name of parameter that will be removed from url
     */
    removeParamFromURL: function (url, name) {
        if (url.indexOf('?') === -1 || url.indexOf(name + '=') === -1) {
            return url;
        }
        var hash,
            params,
            domain = url.split('?')[0],
            paramUrl = url.split('?')[1],
            newParams = [];
        // if there is a hash at the end, store the hash
        if (paramUrl.indexOf('#') > -1) {
            hash = paramUrl.split('#')[1] || '';
            paramUrl = paramUrl.split('#')[0];
        }
        params = paramUrl.split('&');
        for (var i = 0; i < params.length; i++) {
            // put back param to newParams array if it is not the one to be removed
            if (params[i].split('=')[0] !== name) {
                newParams.push(params[i]);
            }
        }
        return domain + '?' + newParams.join('&') + (hash ? '#' + hash : '');
    },

    /**
     * @function
     * @description appends the parameters to the given url and returns the changed url
     * @param {String} url the url to which the parameters will be added
     * @param {Object} params
     */
    appendParamsToUrl: function (url, params) {
        var _url = url;
        for (var key in params) {
            if (params.hasOwnProperty(key)) {
                _url = this.appendParamToURL(_url, key, params[key]);
            }
        }
        return _url;
    },
    /**
     * @function
     * @description extract the query string from URL
     * @param {String} url the url to extra query string from
     **/
    getQueryString: function (url) {
        var qs;
        if (!_isString(url)) {
            return;
        }
        var a = document.createElement('a');
        a.href = url;
        if (a.search) {
            qs = a.search.substr(1); // remove the leading ?
        }
        return qs;
    },
    /**
     * @function
     * @description
     * @param {String}
     * @param {String}
     */
    elementInViewport: function (el, offsetToTop) {
        var top = el.offsetTop,
            left = el.offsetLeft,
            width = el.offsetWidth,
            height = el.offsetHeight;

        while (el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
        }

        if (typeof(offsetToTop) !== 'undefined') {
            top -= offsetToTop;
        }

        if (window.pageXOffset !== null) {
            return (
                top < (window.pageYOffset + window.innerHeight) &&
                left < (window.pageXOffset + window.innerWidth) &&
                (top + height) > window.pageYOffset &&
                (left + width) > window.pageXOffset
            );
        }

        if (document.compatMode === 'CSS1Compat') {
            return (
                top < (window.document.documentElement.scrollTop + window.document.documentElement.clientHeight) &&
                left < (window.document.documentElement.scrollLeft + window.document.documentElement.clientWidth) &&
                (top + height) > window.document.documentElement.scrollTop &&
                (left + width) > window.document.documentElement.scrollLeft
            );
        }
    },

    /**
     * @function
     * @description Appends the parameter 'format=ajax' to a given path
     * @param {String} path the relative path
     */
    ajaxUrl: function (path) {
        return this.appendParamToURL(path, 'format', 'ajax');
    },

    /**
     * @function
     * @description
     * @param {String} url
     */
    toAbsoluteUrl: function (url) {
        if (url.indexOf('http') !== 0 && url.charAt(0) !== '/') {
            url = '/' + url;
        }
        return url;
    },
    /**
     * @function
     * @description Loads css dynamically from given urls
     * @param {Array} urls Array of urls from which css will be dynamically loaded.
     */
    loadDynamicCss: function (urls) {
        var i, len = urls.length;
        for (i = 0; i < len; i++) {
            this.loadedCssFiles.push(this.loadCssFile(urls[i]));
        }
    },

    /**
     * @function
     * @description Loads css file dynamically from given url
     * @param {String} url The url from which css file will be dynamically loaded.
     */
    loadCssFile: function (url) {
        return $('<link/>').appendTo($('head')).attr({
            type: 'text/css',
            rel: 'stylesheet'
        }).attr('href', url); // for i.e. <9, href must be added after link has been appended to head
    },
    // array to keep track of the dynamically loaded CSS files
    loadedCssFiles: [],

    /**
     * @function
     * @description Removes all css files which were dynamically loaded
     */
    clearDynamicCss: function () {
        var i = this.loadedCssFiles.length;
        while (0 > i--) {
            $(this.loadedCssFiles[i]).remove();
        }
        this.loadedCssFiles = [];
    },
    /**
     * @function
     * @description Extracts all parameters from a given query string into an object
     * @param {String} qs The query string from which the parameters will be extracted
     */
    getQueryStringParams: function (qs) {
        if (!qs || qs.length === 0) { return {}; }
        var params = {},
            unescapedQS = decodeURIComponent(qs);
        // Use the String::replace method to iterate over each
        // name-value pair in the string.
        unescapedQS.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'),
            function ($0, $1, $2, $3) {
                params[$1] = $3;
            }
        );
        return params;
    },

    fillAddressFields: function (address, $form) {
        for (var field in address) {
            if (field === 'ID' || field === 'UUID' || field === 'key') {
                continue;
            }

            // ERV-400 - Multiple shipping with use as billing address - custom fields should be filled too
            if (field === 'custom') {
                for (var customField in address[field]) {
                    $form.find('[name$="' + customField + '"]').val(address[field][customField]);
                }
            }

            if (field == 'phone') {
                $form.find('[name$="' + field + '_intltel"]').val(address[field]);
            }

            // if the key in address object ends with 'Code', remove that suffix
            // keys that ends with 'Code' are postalCode, stateCode and countryCode
            $form.find('[name$="' + field.replace('Code', '') + '"]').val(address[field]);
        }

        // ERB-213 - Address forms - USA/Canada states logic (+ Australia and Mexico)
        $form.find('[name$="country"]').trigger('change');
    },
    /**
     * @function
     * @description Updates the number of the remaining character
     * based on the character limit in a text area
     */
    limitCharacters: function () {
        $('form').find('textarea[data-character-limit]').each(function () {
            var characterLimit = $(this).data('character-limit'),
                charCountHtml = String.format(Resources.CHAR_LIMIT_MSG,
                    '<span class="char-remain-count">' + characterLimit + '</span>',
                    '<span class="char-allowed-count">' + characterLimit + '</span>'
                ),
                charCountContainer = $(this).next('div.char-count');
            if (charCountContainer.length === 0) {
                charCountContainer = $('<div class="char-count"/>').insertAfter($(this));
            }
            charCountContainer.html(charCountHtml);
            // trigger the keydown event so that any existing character data is calculated
            $(this).change();
        });
    },
    /**
     * @function
     * @description Binds the onclick-event to a delete button on a given container,
     * which opens a confirmation box with a given message
     * @param {String} container The name of element to which the function will be bind
     * @param {String} message The message the will be shown upon a click
     */
    setDeleteConfirmation: function (container, message) {
        $(container).on('click', '.delete', function () {
            return window.confirm(message);
        });
    },
    /**
     * @function
     * @description Scrolls a browser window to a given x point
     * @param {String} The x coordinate
     */
    scrollBrowser: function (xLocation) {
        $('html, body').animate({scrollTop: xLocation}, 500);
    },

    isMobile: function () {
        var mobileAgentHash = ['mobile', 'tablet', 'phone', 'ipad', 'ipod', 'android', 'blackberry', 'windows ce', 'opera mini', 'palm'],
            idx = 0,
            isMobile = false,
            userAgent = (navigator.userAgent).toLowerCase();

        while (mobileAgentHash[idx] && !isMobile) {
            isMobile = (userAgent.indexOf(mobileAgentHash[idx]) >= 0);
            idx++;
        }
        return isMobile;
    },

    isWebkit: function () {
        return (navigator.userAgent).toLowerCase().indexOf('webkit') >= 0;
    },

    isTouchDevice: function () {
        return matchMedia('(pointer:coarse)').matches;
    },

    /**
    * @function
    * @description init custom scrool bar on webkit touch devices
    * (Purpose: on mobiles scrollbars are by default only shown when scrolling => custom scrollbar is used to improve user experience)
    * @param {DOM element} $scrollContent is DOM element with the scroll content
    */
    customScrollbarInit: function ($scrollContent) {
        if ($scrollContent && $scrollContent.length) {
            if (util.isWebkit() && util.isTouchDevice()) {
                var $customHorizontalScrollbar = $scrollContent.parent('.js-custom-horizontal-scrollbar'),
                    $customHorizontalScrollbarTrack = $customHorizontalScrollbar.find('.js-custom-horizontal-scrollbar-track'),
                    $customHorizontalScrollbarThumb = $customHorizontalScrollbar.find('.js-custom-horizontal-scrollbar-thumb'),
                    visibleContentWidth = $scrollContent[0].clientWidth,
                    completeContentWidth = $scrollContent[0].scrollWidth,
                    isHorizontalScrollRequired = visibleContentWidth < completeContentWidth;

                $scrollContent.off('scroll');

                // custom horizontal scrollbar
                if (isHorizontalScrollRequired && ($customHorizontalScrollbar.length > 0 && $customHorizontalScrollbarThumb.length > 0)) {
                    $customHorizontalScrollbarThumb.css('width', Math.trunc(visibleContentWidth / completeContentWidth * 100) + '%');
                    $customHorizontalScrollbarTrack.show();
                    $customHorizontalScrollbarThumb.show();

                    $scrollContent.on('scroll', function() {
                        if ($customHorizontalScrollbar.length > 0 && $customHorizontalScrollbarThumb.length > 0) {
                            var scrollThumbPosition = 0;

                            if (visibleContentWidth + $scrollContent.scrollLeft() >= completeContentWidth) {
                                $customHorizontalScrollbarThumb.css('left', '');
                                $customHorizontalScrollbarThumb.css('right', '0');
                            } else {
                                scrollThumbPosition = Math.trunc($scrollContent.scrollLeft() / completeContentWidth * 100);

                                // additional control to prevent horizontal jumping out of the scroll area
                                if (scrollThumbPosition > 0) {
                                    $customHorizontalScrollbarThumb.css('left', scrollThumbPosition + '%');
                                }
                                else {
                                    $customHorizontalScrollbarThumb.css('left', '0');
                                    $customHorizontalScrollbarThumb.css('right', '');
                                }

                                $customHorizontalScrollbarThumb.css('right', '');
                            }
                        }
                    });
                }
                else if ($customHorizontalScrollbar.length > 0 && $customHorizontalScrollbarThumb.length > 0) {
                    $customHorizontalScrollbarTrack.hide();
                    $customHorizontalScrollbarThumb.hide();
                }
            } else {
                // .webkit-forced-scrollbar_content::-webkit-scrollbar { } doesn't work if class is added by JS => webkit-forced-scrollbar_content should be in html initially
                $scrollContent.removeClass('webkit-forced-scrollbar_content');
            }
        }
    },

    forceLazyloadImagesByViewport: function ($lazyloadContainer) {
        if ($lazyloadContainer) {
            window.vbqUtils.breakpoint.set([
                {
                    media: window.vbqUtils.breakpoint.getMediaQuery('mobile'),
                    enter: function () {
                        $lazyloadContainer.find('.gl_mobile-image-only:not(.lazyloaded)').each(function (index, el) {
                            window.lazySizes.loader.unveil(el);
                        });
                    }
                },
                {
                    media: window.vbqUtils.breakpoint.getMediaQuery('tablet'),
                    enter: function () {
                        $lazyloadContainer.find('.gl_tablet-image-only:not(.lazyloaded)').each(function (index, el) {
                            window.lazySizes.loader.unveil(el);
                        });
                    }
                },
                {
                    media: window.vbqUtils.breakpoint.getMediaQuery('imageDesktop'),
                    enter: function () {
                        $lazyloadContainer.find('.gl_desktop-image-only:not(.lazyloaded)').each(function (index, el) {
                            window.lazySizes.loader.unveil(el);
                        });
                    }
                },
                {
                    media: window.vbqUtils.breakpoint.getMediaQuery('imagefromDesktopLarge'),
                    enter: function () {
                        $lazyloadContainer.find('.gl_desktoplargeandextralarge-image-only:not(.lazyloaded)').each(function (index, el) {
                            window.lazySizes.loader.unveil(el);
                        });
                    }
                }
            ]);
        }
    }
};

$.fn.customAccordion = function (method, methodData) {
    var _$this = this,
        // For nested accordions separation, remove accordion elements that are children of the main accordion.
        $tabs = _$this.find('.js-accordion-tab').filter(function () {
            return !$(this).parentsUntil(_$this, '.js-accordion-tab').length;
        }),
        $tabsToggle = _$this.find('.js-tabs-accordion-header').attr('role' ,'button').attr('aria-expanded', 'false').attr('tabindex' , '0'),
        methods = {
            init: function () {
                $tabs.removeAttr('style').attr('role' ,'region').attr('aria-hidden', 'true');

                $tabsToggle.each(function (index, el) {
                    var $accordionToggle = $(el),
                        $accordionToggleWrapper = $accordionToggle.parent('.js-tabs-accordion-header-wrapper'),
                        $accordionContent = $accordionToggleWrapper.length ? $accordionToggleWrapper.next() : $accordionToggle.next();

                    // ID generation
                    if ($accordionToggle.attr('id') == undefined) {
                        $accordionToggle.uniqueId();
                    }
                    if ($accordionContent.attr('id') == undefined) {
                        $accordionContent.uniqueId();
                    }
                    $accordionToggle.attr('aria-controls', $accordionContent.attr('id'));
                    $accordionContent.attr('aria-labelledby', $accordionToggle.attr('id'));

                    if ($accordionContent.is(':visible')) {
                        $accordionToggle.addClass('active').attr('aria-expanded', 'true');
                        $accordionContent.addClass('active').attr('aria-hidden', 'false');
                    }
                });

                _$this.on('click keydown', '.js-tabs-accordion-header', function (e) {
                    // mouse click or enter/space key
                    if (e.type == 'click' || (e.type == 'keydown' && (e.keyCode == 13 || e.keyCode == 32))) {
                        // prevent the default action to stop scrolling when space is pressed
                        e.preventDefault();

                        var $this = $(this),
                            $thisWrapper = $this.parent('.js-tabs-accordion-header-wrapper'),
                            $thisContent = $thisWrapper.length ? $thisWrapper.next() : $this.next();

                        if ($this.hasClass('active')) {
                            $this.removeClass('active').attr('aria-expanded', 'false');
                            $thisContent.removeClass('active').attr('aria-hidden', 'true').slideUp();
                            window.vbqUtils.cache.$window.trigger('accordion.tabInactive');
                        }
                        else {
                            var $target = $(e.target).closest('.js-tabs-accordion-header');

                            $tabsToggle.not($target).removeClass('active').attr('aria-expanded', 'false').each(function () {
                                var $toggle = $(this),
                                    $wrapper = $toggle.parent('.js-tabs-accordion-header-wrapper'),
                                    $content = $wrapper.length ? $wrapper.next() : $toggle.next();

                                $content.removeClass('active').attr('aria-hidden', 'true').slideUp();
                            });

                            $this.addClass('active').attr('aria-expanded', 'true');
                            $thisContent.addClass('active').attr('aria-hidden', 'false').slideDown();

                            window.vbqUtils.cache.$window.trigger('accordion.tabActive', [{activeTarget: $target}]);
                            $this.trigger('accordion.tabOpen');
                        }
                    }
                });
                _$this.addClass('accordion-initialized').data('customAccordion', true);
            },
            active: function (index) {
                if (index !== undefined && !$tabs.eq(index).hasClass('active')) {
                    var $prev = $tabs.eq(index).prev();

                    if ($prev.hasClass('js-tabs-accordion-header')) {
                        $prev.trigger('click');
                    } else {
                        $prev.find('.js-tabs-accordion-header').trigger('click');
                    }
                }
            },
            inactive: function (index) {
                if (index !== undefined && $tabs.eq(index).hasClass('active')) {
                    var $prev = $tabs.eq(index).prev();

                    if ($prev.hasClass('js-tabs-accordion-header')) {
                        $prev.trigger('click');
                    } else {
                        $prev.find('.js-tabs-accordion-header').trigger('click');
                    }
                }
            },
            destroy: function () {
                _$this.removeClass('accordion-initialized').data('customAccordion', false);
                $tabsToggle.removeClass('active').removeAttr('role aria-controls aria-expanded');
                $tabs.removeClass('active').removeAttr('role aria-hidden aria-labelledby style');
                _$this.off('click keydown', '.js-tabs-accordion-header');
            }
        };

    if (methods[method]) {
        if (!_$this.data('customAccordion')) {
            $.error('Cannot call ' +  method + ' prior to plugin initialisation');
        } else {
            methods[method](methodData);
        }
    } else if (!_$this.data('customAccordion')) {
        methods.init();
    }

    return this;
};

module.exports = util;
